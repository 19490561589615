<template>
  <div class="card">
    <div class="card-inner">
      <Smiley></Smiley>
      <h1 class="title"> Homey Together </h1>
      <p> Share some kind words and let <span class="owner"> {{ owner }} </span> know you're thinking about him or her.
      </p>
      <div class="text-align-left">
        <label class="label"> Your name </label>
        <input :class="{ input: true, invalid: nameTooLong }" v-model="name" placeholder="Your name">
        <label class="label"> Your message </label>
        <textarea :class="{ input: true, large: true, invalid: messageTooLong }" v-model="message" placeholder="Type some kind words."></textarea>
        <button class="button" :disabled="!canSendMessage" v-on:click="sendLove"> Send your love ❤︎</button>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router'
import Smiley from "../components/Smiley";

export default {
  components: { Smiley },
  data: function () {
    return {
      message: '',
      name: '',
      owner: this.$route.params.owner,
      maxNameLength: 80,
      maxMessageLength: 280,
    }
  },
  computed: {
    nameTooLong: function() {
      return this.name.trim().length > this.maxNameLength;
    },
    messageTooLong: function() {
      return this.message.trim().length > this.maxMessageLength;
    },
    canSendMessage: function () {
      if (this.message.trim().length <= 0) return false;
      if (this.message.trim().length > this.maxMessageLength) return false;
      if (this.name.trim().length <= 0) return false;
      if (this.name.trim().length > this.maxNameLength) return false;
      return true;
    },
  },
  methods: {
    sendLove() {
      const url = `https://webhooks.athom.com/webhook/605db1abe44d3d0bb4f670fa?share_id=${this.$route.params.id}`;
      const message = {
        name: this.name,
        message: this.message.trim(),
      }
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      });
      this.message = '';
      router.push({ name: 'discover', params: { owner: this.owner } })
    }
  }
}
</script>