import {
	athomHomeyTogetherClientId,
	athomHomeyTogetherClientSecret,
} from '../../config';

import {
	AthomCloudAPI,
} from 'athom-api';

class AthomApi {

	constructor() {
		this.user = null;
		this.api = null;

		this.api = new AthomCloudAPI({
			clientId: athomHomeyTogetherClientId,
			clientSecret: athomHomeyTogetherClientSecret,
			redirectUrl: window.location.protocol + '//' + window.location.host,
			baseUrl: new URLSearchParams(window.location.search).get('api-cloud-url')
		});


		this.user = Promise.resolve().then(async () => {

			if (this.api.hasAuthorizationCode()) {
				await this.api.authenticateWithAuthorizationCode();
				window.location.href = window.location.protocol + '//' + window.location.host;
				return;
			}

			if (!await this.api.isLoggedIn())
				throw new Error('not_logged_in');

			return this.api.getAuthenticatedUser();
		});
  
		this.user.catch(err => {
			if (err && err.message !== 'not_logged_in')
				console.error(err);
		});
	}

	getActiveHomeyId() {
		if (window.localStorage) return window.localStorage.getItem('activeHomeyId');
	}

	setActiveHomeyId(homeyId) {
		if (window.localStorage) return window.localStorage.setItem('activeHomeyId', homeyId);
	}

	login() {
		window.location.href = this.api.getLoginUrl() + '&state=' + encodeURIComponent(`origin=${window.location.pathname}`);
	}

	logout() {
		return this.api.logout();
	}

  isLoggedIn() {
    return this.api.isLoggedIn();
  }

  async getUser() {
    return this.api.getAuthenticatedUser();
  }

	async getHomey() {
		const user = await this.user;
		if (!user) return null;
		const homeys = await user.getHomeys();
		return homeys[0];
	}


	async getSharedId() {		
		const homey = await this.getHomey();
		const homeyApi = await homey.authenticate({
			strategy: [ 'cloud' ],
		})
		const app = await homeyApi.apps.getApp({ id: 'com.homeytogether' });
		return app.apiGet('share_link');
	}
	
	async installHomeyTogetherOnHomey() {
		const homey = await this.getHomey();
		const homeyApi = await homey.authenticate({
			strategy: [ 'cloud' ],
		})
		await homeyApi.apps.installFromAppStore({ id: 'com.homeytogether', channel: 'stable' });
		await new Promise((resolve) => {
			setTimeout(resolve, 4000);
		})
	}

	async hasHomeyTogetherInstalled(id) {
		const user = await this.api.getAuthenticatedUser();
		const homey = await user.getHomeyById(id);
		const apps = homey.apps;
		return apps.some(app => app.id === "com.homeytogether");
	}

}

const api = new AthomApi();
export default api;

