<template>
  <div id="app">
    <img class="bg" width="967" height="470" src="./assets/bg.svg">

    <router-view/> 
  </div>
</template>

<script>
// @ is an alias to /src
import './assets/style.css';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'Home'
}

</script>

<style>

</style>
