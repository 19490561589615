<template>
  <div class="card">
    <div class="card-inner">
      <Smiley></Smiley>
      <h1 class="title"> Homey Together </h1>
      <p>Follow the steps to create your own link and share it with family and friends.</p>

      <div class="text-align-left">
        <!-- Step 1: Login -->
        <label class="label large" @click="setView('LOGIN')"> 1. Log in with your Athom account <span v-show="user">  ✅  </span> </label>
        <button class="button" @click="login" v-show="!user && isView('LOGIN')"> Login</button>
        <transition name="slide-fade">
          <div v-if="!!user && isView('LOGIN')" class="details">
            <div class="name-container"> 
              <span class="description-name">  
                <img :src="user.avatar['small']" class="icon rounded"/>
                {{ user.fullname }} </span>
            </div>
            <button class="button install-btn" @click="logout"> Log out
            </button>
          </div>
        </transition>

        <!-- Step 2: App installation -->
        <label class="label large" @click="setView('INSTALL')"> 2. Install the app <span v-show="hasApp || didInstall">  ✅  </span> </label>
        <transition name="slide-fade">
          <div v-if="selectedHomey && isView('INSTALL')" class="details">
            <div class="name-container"> 
              <span class="description-name">  
                <img :src="HomeyIcon" class="icon"/>
                {{ selectedHomey.name }} </span>
            </div>
            <button class="button install-btn" @click="installApp" :disabled="installing || hasApp">
              <span v-if="hasApp"> Installed </span>
              <span v-else-if="installing"> Installing </span>
              <span v-else> Install </span>
            </button>
          </div>
        </transition>

        <!-- Step 3: Create Flow -->
        <label class="label large" @click="setView('FLOW')"> 3. Create a Flow <span v-show="didCreateFlow">  ✅  </span> </label>
        <transition name="slide-fade">
          <div v-if="(hasApp || didInstall) && isView('FLOW')">
            <div>
              <p class="explainer"> Create a Flow using the ‘Homey Together’-card in the ‘When…’ section.
              </p>

              <div class="create-flow-buttons">     
                <button class="button" style="margin-right: 6px" @click="showFlowExample"> View example </button>
                <button class="button" style="margin-left: 6px" @click="createFlow"> Create a Flow </button>
              </div>
            </div>
          </div>
        </transition>
      
        <!-- Step 4: Share Link -->
        <label class="label large" @click="setView('SHARE')">4. Share your personal link <span v-show="share_id">  ✅  </span> </label>
        <transition name="slide-fade">
          <div v-if="link && isView('SHARE')"> 
            <p class="explainer"> Share your link with your friends. When they send you a message, your Flow will trigger. </p>
        
            <div v-if="share_id" class="link-container"> 
              <div class="link-box">
                <a class="share-link" :href="link" target="_blank" rel="noopener" ref="linkToCopy"> {{ link }} </a>
              </div>
              <button class="button copy-btn" style="display: block; width: 100%;" @click="copyLinkToClipBoard"> Copy </button>
            </div>
             <button v-else class="button" @click="getLink" :disabled="creatingLink"> 
              <span v-if="!creatingLink"> Get your unique link </span>
              <span v-else> Receiving link... </span>              
            </button>
          </div>
        </transition>
      </div>
    </div>

    <!-- Display error messages -->
    <transition name="slide-fade">
      <div v-if="error" class="toast">
        <span> <strong> Something went wrong! </strong>  {{ error }} </span>
      </div>
    </transition>
  </div>
</template>
<script>

import api from '@/lib/athomApi';
import crypto from 'crypto';
import Smiley from "../components/Smiley";
import HomeyIcon from "@/assets/homey-icon.png";

export default {
  name: "Home",
  components: {
    Smiley,
  },
  methods: {
    login: function () {
      api.login();
    },
    logout: function () {
      window.localStorage.clear();
      api.logout();
      this.$router.go(0);
    },
    installApp: async function () {
      if (!this.selectedHomey) return null;
      this.installing = true;
      await api.installHomeyTogetherOnHomey();
      this.installing = false;
      this.didInstall = true;
      this.setView("FLOW");
    },
    createFlow: function () {
      window.open(`https://my.homey.app/homeys/${this.selectedHomey.id}/flows`);
      this.didCreateFlow = true;
      window.localStorage.setItem('didCreateFlow', true);
      this.setView("SHARE");
    },
    showFlowExample: function() {
      window.open('https://homey.app/nl-nl/flow/IIzO7y/');
    },
    copyLinkToClipBoard: function () {
      const el = document.createElement('textarea');
      el.value = this.link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.linkCopied = true;
    },
    setView(item) {
      this.view = item;
    },
    isView(item) {
      return item === this.view;
    },
    async getLink() {
      this.creatingLink = true;

      try {
        if (!this.selectedHomey) throw new Error('no Homey');

        const hasAppInstalled = await api.hasHomeyTogetherInstalled(this.selectedHomey.id);
        if (!hasAppInstalled) throw new Error ('App not installed!');
        const id = await api.getSharedId();
        this.share_id = id;
       
      } catch (err) {
        this.creatingLink = false;
        this.error = err.message;
        setTimeout(() => { this.error = null }, 2000);
      }
    }
  },

  data: function () {
    return {

      // Some variables for helping users through the process
      installing: false,
      creatingLink: false,
      error: null,
    
      didInstall: false,
      share_id: null,
      didCreateFlow: window.localStorage ? window.localStorage.getItem('didCreateFlow') : false,
      view: this.didCreateFlow ? 'SHARE' : 'LOGIN',
      HomeyIcon,
    }
  },
  computed: {
    // The link that eventually will be shared
    // It is based on the Homey ID
    link: function () {
      if (!this.user || !this.selectedHomey) return '';
      return `https://together.homey.app/#/${this.user.firstname}/${this.share_id}`;
    },
    hash: function() {
      if (!this.user || !this.selectedHomey) return '';
      const homeyId = this.selectedHomey.id;
      return crypto.createHash('sha256').update(homeyId).digest('hex');
    },
  },

  asyncComputed: {
    // The homey selected in the installation dropdown
    selectedHomey: async function () {
      const loggedIn = await api.isLoggedIn();
      if (!loggedIn) return null;
      return api.getHomey();
    },

    // The logged in user
    user: async function () {
      const loggedIn = await api.isLoggedIn();
      if (!loggedIn) return null;
      return api.getUser();
    },

    // True when the user has the homey together app installed, null otherwise
    hasApp: async function () {
      if (!this.selectedHomey) return null;
      return api.hasHomeyTogetherInstalled(this.selectedHomey.id);
    },

    // Returns true when the user is logged in
    loggedIn: function () {
      return api.isLoggedIn();
    },
  },

  // Keep track of changes to these variables to update the view accordingly
  watch: {
    hasApp: function (newValue) {
      if (this.didCreateFlow) this.view = "SHARE"
      else if (newValue) {
        this.view = "FLOW";
      }
    },
    loggedIn: function (newValue) {
      if (this.didCreateFlow) this.view = "SHARE"
      else if (newValue) {
        this.view = "INSTALL";
      }
    },
    selectedHomey: function (newValue) {
      api.setActiveHomeyId(newValue.id);
    },
    didCreateFlow: function(newValue) {
      if(newValue) {
        this.view = "SHARE";
      }
    }
  }
}
</script>

<style>

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 120px;
}

.label.large {
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.label.small{
  font-size: 16px;
}

.name-container {
  width: 100%;
  flex: 1;
}

.install-btn {
  width: 100px;
}

.link-box {
  width: 100%;
  background: #eee;
  border-radius: 12px;
  white-space: nowrap;
  padding: 12px;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: pointer;
  font-size: 0.9rem;
  height: 42px;
}

.link-box > a {
  color: #888;
  text-decoration: none;
}

.link-container {
  margin-top: 12px;
  align-items: center;
}

.copy-btn {
  width: 140px;
  -webkit-margin-bottom-collapse: 12px;
  height: 42px;
  margin-top: 12px;
}

.icon {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.icon.rounded {
  border-radius: 50%;
}

.description-name {
  display: flex;
  align-items: center;
}

.create-flow-buttons {
  display: flex;
  flex-direction: row;
}

.slide-fade-enter-active {
    transition: opacity .3s linear .2s, max-height .2s linear;  
    max-height: var(--details-view-height);
    overflow-y: hidden;
  }
  .slide-fade-leave-active {
    transition: opacity .2s linear, max-height .2s linear;  
    max-height: var(--details-view-height);
    overflow-y: hidden;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
  }
  
  .toast {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    z-index: 20;
    background: #ff4848;
    color: white;
    border-radius: 24px;
    height: 64px;
    width: 40%;
    left: 30%;
  }

</style>