<template>
    <div class="card">
      <div class="card-inner">
        <Smiley></Smiley>
        <h1 class="title"> You just made {{ owner }} very happy! </h1>
        <p> Do you want to be cheered up by your friends as well? Discover what Homey can do for you. </p>
        <img width="80%" :src="image"/>
        <a class="button" href="https://homey.app/?utm_source=homey-together&utm_medium=discover-button&utm_campaign=homey-together" target="_blank"> Discover Homey</a>
      </div>
    <Hearts></Hearts>
    </div>
</template>

<script>
import image from "@/assets/homey.png";
import Hearts from "../components/Hearts";
import Smiley from "../components/Smiley";

export default {
  components: { Smiley, Hearts },
  data: function () {
    return {
      owner: this.$route.params.owner,
      image: image,
    }
  },
  methods: {
    discoverHomey() {
      window.location.replace("https://homey.app/?utm_source=homey-together&utm_medium=discover-button&utm_campaign=homey-together")
    }
  }
}
</script>
